import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"

import LogoLimeCrime from "../images/clients/limecrime.svg"
import LogoNPR from "../images/clients/npr.svg"
import LogoNPRWhite from "../images/clients/npr-white.svg"
import LogoPG from "../images/clients/proctor-and-gamble.svg"
import LogoZippo from "../images/clients/zippo.svg"
import LogoTimbuk2 from "../images/clients/timbuk-2.svg"
import LogoJeffreeStar from "../images/clients/jeffree-star.svg"
import LogoStonesThrow from "../images/clients/stones-throw.svg"
import LogoSchoolhouse from "../images/clients/schoolhouse.svg"
import LogoTaylorGang from "../images/clients/taylor-gang.svg"

import WorkLimeCrime from "../images/work/work-lime-crime-desktop.png"
import WorkGrainviz from "../images/work/work-grainviz.png"

const HomePage = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityHome {
        edges {
          node {
            id
            title
            _rawBody(resolveReferences: {maxDepth: 10})
            meta {
              _type
              _key
              meta_title
              meta_description
              cover {
                asset {
                  id
                  gatsbyImageData
                }
              }
            }
            footer {
              _id
              title
            }
            header {
              title
              _id
            }
            _id
          }
        }
      }
    }
  `)

  const doc = data.allSanityHome.edges[0].node;

  return (
    <Layout header={doc.header.title} footer={doc.footer.title} >
      {doc._rawBody.map((block) => {

        switch (block._type) {
          case "home_banner":
            return(
              <section key={block._key} className="banner banner--home graphic--1">
                <div className="site__wrapper">
                  <div className="banner-copy">
                  <h1>{block.title}</h1>
                    <p>{block.blurb[0].children[0].text}</p>
                  </div>
                </div>
                <div className="banner__img">
                  <div className="grainy"></div>
                </div>
              </section>
            )
          case "clients_block":
            return(
              <section key={block._key} className="client-block clients">
                <div className="site__wrapper">
                  <div className="site__grid-middle-center">
                    <div className="site__col-6_sm-12 clients__blurb">
                      <div className="site__grid sm-center">
                        <div className="site__col-10">
                          <h2>Select Clients</h2>
                          <p>Everything from large scale replatforming to small iterations, from ongoing optimization to blue sky exploration.</p>
                        </div>
                      </div>
                    </div>
                    <div className="site__col-6_sm-12">
                      <div className="clients__logos site__grid-3">
                        <div className="clients__indv site__col">
                          <img src={LogoLimeCrime} alt="" />
                        </div>
                        <div className="clients__indv site__col">
                          <img src={LogoNPR} alt="" />
                        </div>
                        <div className="clients__indv site__col">
                          <img src={LogoPG} alt="" />
                        </div>
                        <div className="clients__indv site__col">
                          <img src={LogoZippo} alt="" />
                        </div>
                        <div className="clients__indv site__col">
                          <img src={LogoTimbuk2} alt="" />
                        </div>
                        <div className="clients__indv site__col">
                          <img src={LogoJeffreeStar} alt="" />
                        </div>
                        <div className="clients__indv site__col">
                          <img src={LogoStonesThrow} alt="" />
                        </div>
                        <div className="clients__indv site__col">
                          <img src={LogoSchoolhouse} alt="" />
                        </div>
                        <div className="clients__indv site__col">
                          <img src={LogoTaylorGang} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )
          case 'quote':
            return(
              <section key={block._key} className="quote">
                <div className="site__wrapper">
                  <div className="site__grid-center-middle quote__content">
                    <div className="site__col-8_sm-12">
                      <p>Nate and the team at Bold were excellent partners! We had a tight timeline and very few resources on our end, but Nate kept everything running like clockwork without ever breaking a sweat!</p>
                      <p>They met our time frame and took every one of the many wrenches we threw in to the mix and made it work seamlessly!  We will definitely work with them again.</p>

                      <div className="quote__author">
                        <img src={LogoNPRWhite} alt="" />
                        <h4>Barbara Sopato</h4>
                        <h5>NPR Director, E-Commerce and Consumer Products</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grainy"></div>
              </section>
            )
          case 'featured_work':
            return(
            <section key={block._key} className="work">
              <div className="site__wrapper">
                <div className="site__grid-middle-center">
                  <div className="site__col-5_sm-10 blurb">
                    <h2>Select Work</h2>
                    <p>Everything from large scale replatforming to small iterations, from ongoing optimization to blue sky exploration.</p>
                  </div>
                </div>
                <div className="site__grid-center">

                  <div className="site__col-6_sm-12 work__indv">
                    <a href="https://www.figma.com/file/6HGP1AB8peby2DYayoUQUG/Work-Lime-Crime?node-id=0%3A1">
                      <img src={WorkLimeCrime} alt="" />
                    </a>
                  </div>
                  <div className="site__col-6_sm-12 work__indv">
                    <a href="https://www.figma.com/file/QUr9GnU05G9S7p2iWN9EDb/Work-Grainviz?node-id=0%3A1">
                      <img src={WorkGrainviz} alt="" />
                    </a>
                  </div>
                  <div className="site__col-4_sm-12">
                    <Link to="/work" className="button button--full">See more work</Link>
                  </div>
                </div>
              </div>
            </section>
            )
          default:
            return(
              <h2 key={block._key}>Default</h2>
            )
        }
        
      })}
    </Layout>
  )
}

export default HomePage



// import React from "react"

// import Layout from "../components/layout"
// import SEO from "../components/seo"
// import Rellax from 'rellax'
// import {Link} from 'gatsby'



// import WorkLimeCrime from "../images/work/work-lime-crime-desktop.png"
// import WorkGrainviz from "../images/work/work-grainviz.png"

// class IndexPage extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {date: new Date()};
//   }

//   componentDidMount() {
//     this.rellax = new Rellax('.rellax')
//   }

//   componentWillUnmount() {
//   }

//   render() {
//     return (
//       <Layout>
//         <SEO title="Home" />

          
          
          

//             <div className="banner__img">
//               <div className="grainy"></div>
//               <div className="graphic__circle graphic__circle--1 rellax" data-rellax-speed="-5"></div>
//               <div className="graphic__circle graphic__circle--2 rellax" data-rellax-speed="-5"></div>
//             </div>
//           </section>

          
//         </Layout>    
//     );
//   }
// }


// export default IndexPage
