import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LogoNPRWhite from "../../images/clients/npr-white.svg"

const Work = ({ data }) => {
  
  return(
    <Layout footer="Main footer">

      
      <Seo title="Work" />

      <article>
        <section className="work__banner banner banner--work graphic--3">
          <div className="site__wrapper">
            <div className="banner-copy">
              <h1>Work I've done</h1>
            </div>
          </div>
          <div className="banner__img">
            <div className="grainy"></div>
          </div>
        </section>

        <section className="work">
          <div className="site__wrapper">
            <div className="site__grid no--margin">

              {data.allSanityWork.edges.map((work) => {
                return(
                  <div className="site__col-6_sm-12 work__indv" key={work.node.id} data-coming-soon={work.node.coming_soon}>
                    <a className="work__link" href={`/work/`+ work.node.slug.current}>
                      <div className="work__color-block grainy"  style={{backgroundColor: work.node.color }}>
                        {work.node.coming_soon === true && 
                          <div className="badge__overlay">
                            <div className="badge badge--coming-soon">Coming soon</div>
                          </div>
                        }
                  
                        <img src={work.node.banner.asset.url} alt="Work" loading="lazy" />
                        
                      </div>
                      <h3>{work.node.title}</h3>
                      
                      <h5><span>Role{work.node.roles.length > 1 && 's'}:</span>
                        {work.node.roles.map((role, index) => {
                          return <span key={index}>{role.title}</span>
                        })}
                      </h5>
                      
                      <h5><span>Tool{work.node.tools.length > 1 && 's'}:</span>
                        {work.node.tools.map((tool, i) => {
                          return <span key={i}>{tool.title}</span>
                        })}
                      </h5>

                    </a>
                  </div>
                )
              })
              }

            </div>
          </div>
        </section>
        <section className="quote">
          <div className="site__wrapper">
            <div className="site__grid-center-middle quote__content">
              <div className="site__col-8_sm-12">
                <p>Nate and the team at Bold were excellent partners! We had a tight timeline and very few resources on our end, but Nate kept everything running like clockwork without ever breaking a sweat!</p>
                <p>They met our time frame and took every one of the many wrenches we threw in to the mix and made it work seamlessly!  We will definitely work with them again.</p>

                <div className="quote__author">
                  <img src={LogoNPRWhite} alt="" />
                  <h4>Barbara Sopato</h4>
                  <h5>NPR Director, E-Commerce and Consumer Products</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="grainy"></div>
        </section>
      </article>

    </Layout>
  )
}

export const query = graphql`
{
  allSanityWork {
    edges {
      node {
        id
        url
        title
        color
        banner {
          asset {
            url
          }
        }
        coming_soon
        client {
          logo {
            asset {
              url
            }
          }
        }
        slug {
          current
        }
        body {
          list
          style
          _type
          _key
        }
        _rawBody
        tools {
          title
        }
        roles{
          title
        }
      }
    }
  }
}

`

export default Work