import PropTypes from "prop-types"
import React from "react"
import Logo from "./logo"
import Navigation from "./navigation"
import './scss/_header.scss'

const Header = ({ children, darkHeader }) => (
  <header className="site__header" data-dark-header={darkHeader}>
    <div className="site__wrapper">
      <Logo />
      <Navigation />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
