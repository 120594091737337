import { Link } from "gatsby"
import React from "react"
import './scss/_logo.scss'

const Logo = ({ children }) => (
    <Link className="logo" to="/">
        <div className="logo-bar-v logo-n logo-1"></div>
        <div className="logo-bar-h logo-n logo-2"></div>
        <div className="logo-bar-v logo-n logo-3"></div>
        <div className="logo-bar-h logo-v logo-4"></div>
        <div className="logo-bar-v logo-v logo-5"></div>
    </Link>
)

export default Logo