import React, { Component } from 'react';
import './scss/_cursor.scss'

class Cursor extends Component {
  state = {
    left: 0,
    top: 0
  }

  componentDidMount() {
    document.addEventListener("mousemove", this.handleCursor);

    var links = document.querySelectorAll('a');

    links.forEach((link) => {
      link.addEventListener('mouseenter', () => {
        document.querySelector('.site__cursor').classList.add('site__cursor--active');
      });

      link.addEventListener('mouseleave', () => {
        document.querySelector('.site__cursor').classList.remove('site__cursor--active');
      });
    });

    var buttons = document.querySelectorAll('.button');

    buttons.forEach((button) => {
      button.addEventListener('mouseenter', () => {
        document.querySelector('.site__cursor').classList.add('site__cursor--active');
      });

      button.addEventListener('mouseleave', () => {
        document.querySelector('.site__cursor').classList.remove('site__cursor--active');
      });
    });

    var comingSoons = document.querySelectorAll('[data-coming-soon="true"]');

    comingSoons.forEach((comingSoon) => {
      comingSoon.addEventListener('mouseenter', () => {
        document.querySelector('.site__cursor').classList.add('site__cursor--no-drop');
      });

      comingSoon.addEventListener('mouseleave', () => {
        document.querySelector('.site__cursor').classList.remove('site__cursor--no-drop');
      });
    });

  }

  componentWillUnmount() {
    // Make sure to remove the DOM listener when the component is unmounted.
    document.removeEventListener("mousemove", this.handleCursor);
  }

  handleCursor = (e) => {
    const cursor = document.querySelector('.site__cursor-point');     
    const cursorRing = document.querySelector('.site__cursor-ring'); 
    
    var y = e.pageY;
    var x = e.pageX;


    
    cursor.setAttribute('style','top:'+y+'px; left:'+ x+'px;');
    setTimeout(function(){
      cursorRing.setAttribute('style','top:'+y+'px; left:'+ x+'px;');
    }, 100)
  }

  render() {
    return (
      <div className='site__cursor'>
        <div className='site__cursor-point'></div>
        <div className='site__cursor-ring'></div>
      </div>
    )
  }
}

export default Cursor;