import React from "react"

import Rellax from 'rellax'

import Layout from "../../components/layout"
import Seo from "../../components/seo"

class Experiment1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {date: new Date()};
  }

  componentDidMount() {
    this.rellax = new Rellax('.rellax')
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <Layout>
        <Seo title="Experiment 1" />
      </Layout>
    );
  }
}

export default Experiment1
