import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {PortableText} from '@portabletext/react'

const WorkPage = (props) => {

  const workData = props.pageContext.node;

  console.log(workData)

  return (
    <Layout darkHeader="true" footer="Main footer">
      <Seo title={workData.title} />
      <section className="page__work">
      
        <div className="work__mast">
          <div className="site__wrapper">
            <h1>{workData.title}</h1>
            <div className="work__meta">
              <h4 className="work__tools"><span>Role{workData.roles.length > 1 && 's'}:</span>{workData.roles.map((role, i)=> {
                return <span key={i}>{role.title}</span>
              })}</h4>
              <h4 className="work__tools"><span>Tool{workData.tools.length > 1 && 's'}:</span>{workData.tools.map((tool, i)=> {
                return <span key={i}>{tool.title}</span>
              })}</h4>
            </div>

            {workData.url &&
              <a href={workData.url} className="work__website button button--primary" target="_blank" rel="noopener noreferrer">View site</a>
            }
          </div>
        </div>
        <div className="site__grid-noGutter work__banner">
          <div className="site__col-12">
            <div className="work__color-block work__color-block--large grainy" style={{backgroundColor: workData.color }}>
            <img src={workData.banner.asset.url} alt="Bam" />
            </div>
          </div>
        </div>
        <div className="site__wrapper">
          <div className="site__grid-center work__body">
            <div className="site__col-8_sm-12">

            <PortableText value={workData._rawBody} />
            
            </div>
          </div>
        </div>
        <div className="work__images">
          <div className="site__wrapper">
            <div className="site__grid">
              {workData.work_images.map((workImage,i) => {
                return(
                  <div key={i} className="site__col-12">
                    <img src={workImage.asset.url} alt="Example of my work on the project" loading="lazy" />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>


    </Layout>
  )
}
export default WorkPage