import React from "react"
import Rellax from 'rellax'
import Nate from "../images/about.png"

import Layout from "../components/layout"
import Seo from "../components/seo"

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {date: new Date()};
  }

  componentDidMount() {
    this.rellax = new Rellax('.rellax')
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <Layout footer="Main footer">
        <Seo title="About" />

        <section className="work__banner banner banner--work graphic--4">
          <div className="site__wrapper">
            <div className="banner-copy">
              <h1>About Nate</h1>
            </div>
          </div>
          <div className="banner__img">
            <div className="grainy"></div>
            {/* <div className="graphic__circle graphic__circle--1 rellax" data-rellax-speed="-6"></div>
            <div className="graphic__circle graphic__circle--2 rellax" data-rellax-speed="-6"></div> */}
          </div>
        </section>
        <section className="work">
          <div className="site__wrapper">
            <div className="site__grid">
              <div className="site__col-6_sm-12">
                <div className="site__grid">
                  <div className="site__col-10_sm-12">
                    <div className="site__lead">
                      <h2>Product designer and frontend developer meets radio host / gardener / cook / cyclist / dog owner</h2>
                      <p>Hi, I'm Nate.  I'm a product designer and frontend developer at Bold Commerce, which is a remote first company headquartered in Winnipeg, Canada.  I mainly work on our agency side nowadays, but in my time I've worked on some of our products and help build out some of our labs projects.</p>
                      <p>When I'm not doing the ol' web thing, you can find me on UMFM hosting my radio show International Feel.  It's an ode to the obscure, funky side of world music.  </p>
                      <p>I'm also a big fan of cooking, so you can also find me sharpening my knife, or digging into a new recipe.  Speaking of digging... I'm also planning on expanding my garden, ask me about it.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="site__col-6_sm-12">
                <img className="about__img" src={Nate} alt="" />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default About
