/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import Cursor from "./cursor"

import "./layout.scss"
import "./scss/_typography.scss"
import "./scss/_grid.scss"
import "./scss/_utils.scss"
import "./scss/_vars.scss"

  

const Layout = ({ children, header, footer, darkHeader}) => {

  React.useEffect(() => {

  }, []);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  return (
    <>
      <Cursor />
      <Header siteTitle={data.site.siteMetadata.title} header={header} darkHeader={darkHeader}  />
      <main>
        {children}
      </main>
      <Footer footer={footer}/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string,
  footer: PropTypes.string,
}

export default Layout
