import { Link } from "gatsby"
import React from "react"
import Resume from "../files/resume.pdf"
import './scss/_navigation.scss'

const Navigation = ({ children }) => (
    <nav className="navigation">
        <Link activeClassName="navigation--active" to="/work/">Work</Link>
        <Link activeClassName="navigation--active" to="/about/">About</Link>
        <a href="mailto:natevandervis@gmail.com" title="Say hello">Contact</a>
        <a href={Resume} title="My Resume">Resume</a>
    </nav>
)

export default Navigation